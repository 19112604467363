import React from 'react'

import { LogoLightWithText, LogoDarkWithText, LogoDark, LogoLight } from 'constants/whitelabel/if/icons'
import { LogoWithText, Logo } from 'constants/whitelabel/ronin/icons'
import { LogoWithText as BeamLogoText, Logo as BeamLogo } from 'constants/whitelabel/beam/icons'
import { Box, MenuEntry } from 'uikit'
import IFTypography from 'componentsV2/IFTypography'
import { aethirLinks, links, MoreLink, roninLinks } from 'uikit/widgets/Menu/config'

const config: MenuEntry[] = [
  {
    label: 'Invest',
    href: '/explore',
    pattern: /(\/explore)|(\/launchpad\/project)|(\/vesting)|(\/manage)/,
    icon: 'Invest',
    items: null,
  },
  {
    label: 'Trade',
    href: `/swap`,
    pattern: /(\/swap)|(\/liquidity)|(\/rewards)/,
    icon: 'Swap',
    items: null,
  },
].filter((val) => val)

const roninConfig: MenuEntry[] = [
  {
    label: 'Launchpad',
    href: '/explore',
    pattern: /(\/explore)|(\/launchpad\/project)|(\/vesting)/,
    icon: 'Invest',
    items: null,
  },
  {
    label: 'Participated',
    href: `/manage`,
    pattern: /(\/manage)/,
    icon: 'Participated',
    items: null,
  },
].filter((val) => val)

const beamConfig: MenuEntry[] = [
  {
    label: 'Launchpad',
    href: '/explore',
    pattern: /(\/explore)|(\/launchpad\/project)|(\/vesting)/,
    icon: 'Invest',
    items: null,
  },
  {
    label: 'Participated',
    href: `/manage`,
    pattern: /(\/manage)/,
    icon: 'Participated',
    items: null,
  },
].filter((val) => val)

const aethirConfig: MenuEntry[] = [
  {
    label: 'What are Aethir Nodes?',
    href: 'https://app.aethir.com',
    pattern: /(\/explore)|(\/launchpad\/project)|(\/vesting)/,
    icon: 'FAQ',
    items: null,
  },
].filter((val) => val)

const carvConfig: MenuEntry[] = [
  {
    label: 'Purchase on Solana',
    href: 'https://verifier-solana.carv.io',
    pattern: /(\/explore)|(\/launchpad\/project)|(\/vesting)/,
    icon: 'Invest',
    items: null,
  },
].filter((val) => val)

type CompanyLinks = {
  [key in Links]?: string
}
interface CompanyContext {
  brand: string
  companyName: string
  logoUrl?: string
  logoSrc: string
  logoDesktopDark: React.ReactElement
  logoDesktopLight: React.ReactElement
  logoMobileDark: React.ReactElement
  logoMobileLight: React.ReactElement
  faqLink?: string
  isWhitelabel?: boolean
  themeName?: string
  links?: CompanyLinks
  showAddToWallet?: boolean
  mainUrl?: string
  menuLinks?: MenuEntry[]
  moreLinks?: MoreLink[]
  hideMenu?: boolean
  footerImage?: React.ReactElement
  footerImageMarginLeft?: string
  isJustSale?: boolean
  hideBreadcrumb?: boolean
  hideSettings?: boolean
}

type CompanyContextConstants = {
  [key: string]: CompanyContext
}

export enum Brands {
  'IF' = 'if',
  'RONIN' = 'ronin',
  'BEAM' = 'beam',
  'AETHIR' = 'aethir',
  'SOPHON' = 'sophon',
  'CARV' = 'carv',
}

export enum Links {
  'BLOG' = 'blog',
  'X' = 'x',
  'TELEGRAM' = 'telegram',
  'DISCORD' = 'discord',
  'EXPLORER' = 'explorer',
  'PRIVACY' = 'privacy',
  'TERMS' = 'terms',
  'COOKIE' = 'cookie',
}

const IF_LINKS: CompanyLinks = {
  [Links.BLOG]: 'https://blog.impossible.finance',
  [Links.X]: 'https://x.com/ImpossibleFi',
  [Links.TELEGRAM]: 'https://t.me/ImpossibleFinance',
  [Links.DISCORD]: 'https://discord.com/invite/SyF3RzxQCZ',
  [Links.COOKIE]: 'https://impossible.finance/cookie',
  [Links.TERMS]: 'https://impossible.finance/terms',
}

const RONIN_LINKS: CompanyLinks = {
  [Links.BLOG]: 'https://roninchain.com/blog',
  [Links.X]: 'https://twitter.com/Ronin_Network',
  [Links.TELEGRAM]: 'https://discord.com/invite/roninnetwork',
  [Links.EXPLORER]: 'https://app.roninchain.com/',
  [Links.COOKIE]: 'https://impossible.finance/cookie',
  [Links.TERMS]: 'https://impossible.finance/terms',
}

const BEAM_LINKS: CompanyLinks = {
  [Links.BLOG]: 'https://medium.com/@meritcircle',
  [Links.X]: ' https://twitter.com/BuildOnBeam',
  [Links.TELEGRAM]: ' https://t.me/meritcircle',
  [Links.DISCORD]: 'https://discord.gg/meritcircle',
  [Links.COOKIE]: 'https://impossible.finance/cookie',
}

const AETHIR_LINKS: CompanyLinks = {
  [Links.BLOG]: 'https://www.aethir.com/blog',
  [Links.X]: 'https://twitter.com/AethirCloud',
  [Links.PRIVACY]: 'https://www.aethir.com/privacy-policy',
  [Links.DISCORD]: 'https://discord.gg/6KDKznaRTr',
}

const SOPHON_LINKS: CompanyLinks = {
  [Links.BLOG]: 'https://www.sophon.xyz',
  [Links.X]: 'https://twitter.com/sophon',
  [Links.PRIVACY]: 'https://sophon.xyz/privacypolicy',
  [Links.TERMS]: 'https://sophon.xyz/terms',
}

const CARV_LINKS: CompanyLinks = {
  [Links.DISCORD]: 'https://discord.com/invite/HPgZMwvSXB',
  [Links.TELEGRAM]: 'https://bit.ly/JoinCARVOfficialTelegram',
  [Links.BLOG]: 'https://medium.com/carv-official',
  [Links.X]: 'https://twitter.com/carv_official',
}

const CompanyContext = React.createContext<CompanyContext>({
  brand: Brands.IF,
  companyName: 'Default',
  logoSrc:
    'https://if-cms-content.s3.eu-west-2.amazonaws.com/staging/10c0a77b-f039-47a3-92c3-396481588a0f.svg/10c0a77b-f039-47a3-92c3-396481588a0f.svg',
  logoDesktopDark: <LogoDarkWithText />,
  logoDesktopLight: <LogoLightWithText />,
  logoMobileDark: <LogoDark />,
  logoMobileLight: <LogoLight />,
  isWhitelabel: false,
  links: IF_LINKS,
  showAddToWallet: true,
  faqLink: 'https://impossiblefinance.notion.site/Launchpad-FAQ-2bba9329b32640ca9c68bf84e0aa20a3',
  menuLinks: config,
  logoUrl: '/',
})

const COMPANY_DATA: CompanyContextConstants = {
  if: {
    brand: Brands.IF,
    companyName: 'Impossible.Finance',
    logoSrc:
      'https://if-cms-content.s3.eu-west-2.amazonaws.com/staging/10c0a77b-f039-47a3-92c3-396481588a0f.svg/10c0a77b-f039-47a3-92c3-396481588a0f.svg',
    logoDesktopDark: <LogoDarkWithText />,
    logoDesktopLight: <LogoLightWithText />,
    logoMobileDark: <LogoDark />,
    logoMobileLight: <LogoLight />,
    links: IF_LINKS,
    showAddToWallet: true,
    faqLink: 'https://impossiblefinance.notion.site/Launchpad-FAQ-2bba9329b32640ca9c68bf84e0aa20a3',
    menuLinks: config,
    logoUrl: '/',
    moreLinks: links,
  },
  ronin: {
    brand: Brands.RONIN,
    mainUrl: 'https://ronin.impossible.finance',
    companyName: 'Ronin',
    logoSrc:
      'https://if-cms-content.s3.eu-west-2.amazonaws.com/production/ronin%20network.svg/ronin%20network-1697222670.svg',
    logoDesktopDark: <LogoWithText />,
    logoDesktopLight: <LogoWithText />,
    logoMobileDark: <Logo />,
    logoMobileLight: <Logo />,
    isWhitelabel: true,
    themeName: 'ronin',
    links: RONIN_LINKS,
    faqLink:
      'https://impossiblefinance.notion.site/impossiblefinance/Impossible-Finance-Launchpad-FAQ-73be73d103e44f1e81f3f1098a935b2a',
    menuLinks: roninConfig,
    footerImage: (
      <>
        <Box width="max-content" display="flex" style={{ alignItems: 'center', marginRight: '10px' }}>
          <IFTypography fontFamily="Roboto" variant="body2" ifcolor="text">
            Powered by
          </IFTypography>
        </Box>
        <LogoWithText />
      </>
    ),
    moreLinks: roninLinks,
    logoUrl: 'https://roninchain.com/',
  },
  beam: {
    brand: Brands.BEAM,
    mainUrl: 'https://beam.impossible.finance',
    companyName: 'Beam',
    logoSrc:
      'https://if-cms-content.s3.eu-west-2.amazonaws.com/production/ronin%20network.svg/ronin%20network-1697222670.svg',
    logoDesktopDark: <BeamLogoText />,
    logoDesktopLight: <BeamLogoText />,
    logoMobileDark: <BeamLogo />,
    logoMobileLight: <BeamLogo />,
    isWhitelabel: true,
    themeName: 'beam',
    links: BEAM_LINKS,
    faqLink:
      'https://impossiblefinance.notion.site/impossiblefinance/Impossible-Finance-Launchpad-FAQ-73be73d103e44f1e81f3f1098a935b2a',
    menuLinks: beamConfig,
    moreLinks: [],
    footerImage: <BeamLogoText />,
    logoUrl: '/',
  },
  aethir: {
    brand: Brands.AETHIR,
    mainUrl: 'https://checker.aethir.com',
    companyName: 'Aethir',
    logoSrc: '',
    logoDesktopDark: <img style={{ width: '114px' }} src="/images/whitelabel/aethir/logo.png" />,
    logoDesktopLight: <img style={{ width: '114px' }} src="/images/whitelabel/aethir/logo.png" />,
    logoMobileDark: <img style={{ width: '114px', maxWidth: '44px' }} src="/images/whitelabel/aethir/logoMobile.png" />,
    logoMobileLight: (
      <img style={{ width: '114px', maxWidth: '44px' }} src="/images/whitelabel/aethir/logoMobile.png" />
    ),
    isWhitelabel: true,
    themeName: 'aethir',
    links: AETHIR_LINKS,
    faqLink: 'https://docs.aethir.com/checker-nodes-explained/how-to-purchase-node',
    menuLinks: aethirConfig,
    footerImage: <img style={{ width: '114px' }} src="/images/whitelabel/aethir/logo.png" />,
    isJustSale: true,
    moreLinks: aethirLinks,
    hideBreadcrumb: false,
    logoUrl: '/',
  },
  sophon: {
    brand: Brands.SOPHON,
    mainUrl: 'https://nodes.sophon.xyz',
    companyName: 'Sophon',
    logoSrc: '',
    logoDesktopDark: <img style={{ width: '60px' }} src="/images/whitelabel/sophon/logo.svg" />,
    logoDesktopLight: <img style={{ width: '60px' }} src="/images/whitelabel/sophon/logo.svg" />,
    logoMobileDark: <img style={{ width: '60px', maxWidth: '60px' }} src="/images/whitelabel/sophon/logo.svg" />,
    logoMobileLight: <img style={{ width: '60px', maxWidth: '60px' }} src="/images/whitelabel/sophon/logo.svg" />,
    isWhitelabel: true,
    themeName: 'aethir',
    links: SOPHON_LINKS,
    faqLink: 'https://docs.sophon.xyz/nodes/node-sale',
    menuLinks: [],
    moreLinks: [],
    footerImage: <img style={{ width: '60px' }} src="/images/whitelabel/sophon/logo.svg" />,
    footerImageMarginLeft: '-30px',
    isJustSale: true,
    hideBreadcrumb: false,
    logoUrl: '/',
  },
  carv: {
    brand: Brands.CARV,
    mainUrl: 'https://verifier.carv.io',
    companyName: 'Carv',
    logoSrc: '',
    logoDesktopDark: <img style={{ width: '100px' }} src="/images/whitelabel/carv/logoFooter.svg" />,
    logoDesktopLight: <img style={{ width: '100px' }} src="/images/whitelabel/carv/logoFooter.svg" />,
    logoMobileDark: <img style={{ width: '60px', maxWidth: '60px' }} src="/images/whitelabel/carv/logoMobile.svg" />,
    logoMobileLight: <img style={{ width: '60px', maxWidth: '60px' }} src="/images/whitelabel/carv/logoMobile.svg" />,
    isWhitelabel: true,
    themeName: 'carv',
    links: CARV_LINKS,
    faqLink: 'https://node.carv.io/',
    menuLinks: carvConfig,
    moreLinks: [],
    footerImage: <img style={{ width: '100px' }} src="/images/whitelabel/carv/logoFooter.svg" />,
    footerImageMarginLeft: '-50px',
    isJustSale: true,
    hideBreadcrumb: false,
    logoUrl: '/',
  },
}

const CompanyContextProvider = ({ children }) => {
  let brand = 'if'

  if (process.env.REACT_APP_WHITELABEL_BRAND?.length > 0) {
    brand = process.env.REACT_APP_WHITELABEL_BRAND
  }

  const { companyName, logoSrc, ...rest } = COMPANY_DATA?.[brand] || COMPANY_DATA.if

  return <CompanyContext.Provider value={{ companyName, logoSrc, ...rest }}>{children}</CompanyContext.Provider>
}

export { CompanyContext, CompanyContextProvider }
