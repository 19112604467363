import { useTranslation } from 'contexts/Localization'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { PaidStakeSaleConfig, PurchasableIDO, IDOSaleToken, IDO } from 'state/v2_types'
import { OpenNewIcon, useIsDrawerOpen, useModalV2 } from 'uikit'
import { getWithExpiry } from 'utils/localStorageHelper'

import { convertFromWei, getBalanceInWei } from 'utils/formatBalance'
import useTokenBalance from 'hooks/useTokenBalance'
import BigNumber from 'bignumber.js'
import { Token } from 'config/constants/types'
import styled, { useTheme } from 'styled-components'
import { Box, Button } from '@mui/material'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import InputArea from 'componentsV2/InputArea/InputArea'
import { ApprovalState } from 'hooks/useApproveCallback'
import ActionBtnWithApproveFlow from 'componentsV2/ActionBtnWithApproveFlow/ActionBtnWithApproveFlow'
import { usePurchaseV2, useGetMaxPaymentInWeiV2 } from 'hooks/useIFASale'
import ModalV2 from 'uikit/widgets/Modal/ModalV2'
import { useSaleUserData } from 'state/idos/hooks'
import { useToast } from 'state/hooks'
import { QUICK_ACTION_DRAWER_TYPE } from 'views/Manage/Manage'
import { getBscScanLink } from 'utils'
import getMoreTokenAddress from 'utils/getMoreTokenAddress'
import { StepRequirementEnum } from 'state/types'
import { useIdoApprove } from 'hooks/useApprove'
import PurchaseCardDisclaimer, { localStorageKey } from '../PurchaseCardDisclaimer/PurchaseCardDisclaimer'

import SaleDetailCard from '../SaleDetailCard'
import ModalPromo from './components/ModalPromo'
import HelpIcon from '@mui/icons-material/Help'
import IFTooltip from 'componentsV2/IFTooltip'
import PromoBtnIcon from 'uikit/components/Svg/Icons/PromoBtnIcon'
import { AETHIR_CODES, PROMO_CODES } from './consts'
import { CompanyContext } from 'contexts/CompanyContext'
import ProgressBar from '../SaleBriefCard/ProgressBar'
const BodyContainer = styled.div`
  width: 100%;
`

const BodyHeaderContainer = styled.div<{ canPurchase: boolean; isSidebar: boolean; isMaxPurchase: boolean }>`
  display: flex;
  border-radius: 20px;
  background: ${({ theme }) => theme.common.headerContainerBgSaleCard};
  margin: 15px auto 20px;
  flex-direction: column;
  padding: 15px 10px;
  ${({ theme }) => theme.mediaQueries?.md} {
    flex-direction: ${({ isSidebar }) => (isSidebar ? 'column' : 'row')};
    padding: 15px;
  }

  span {
    text-transform: unset;
  }

  & > div:not(:nth-child(2)) {
    flex-basis: ${({ isMaxPurchase }) => (isMaxPurchase ? 'none' : 'calc((100% - 1px) / 2);')};
  }
`

const Divider = styled.div<{ isSidebar: boolean }>`
  border-left: 0px;
  border-top: 1px solid ${({ theme }) => theme.colorsV2?.light};
  border-right: 1px solid ${({ theme }) => theme.colorsV2?.light};

  margin: ${({ isSidebar }) => (isSidebar ? '8px 0' : '0')};
  ${({ theme }) => theme.mediaQueries?.md} {
    ${({ isSidebar }) =>
      isSidebar
        ? `
      border-left: 0px;
  border-top: 1px solid ${({ theme }) => theme.colorsV2?.light};
      `
        : `
      border-left: 1px solid ${({ theme }) => theme.colorsV2?.light};
      border-top: 0px;
      `}
  }
`
const TotalAllocationSection = styled.div<{ isSidebar: boolean }>`
  display: flex;

  flex-direction: column;

  padding: 0 20px 7px;

  & > div {
    display: flex;
    margin: 3px 0 2px;
  }

  & > div,
  & > span:last-child {
    align-self: center;
  }

  ${({ theme }) => theme.mediaQueries?.md} {
    padding: 0;

    & > div,
    & > span:last-child {
      align-self: ${({ isSidebar }) => (isSidebar ? 'center' : 'flex-start')};
    }
  }
`

const PurchasedAllocationSection = styled.div<{ isSidebar: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 15px 20px 0;

  ${({ theme }) => theme.mediaQueries?.md} {
    padding: ${({ isSidebar }) => (isSidebar ? '0' : '0 0 0 25px')};
  }
`

const TotalPurchasedSection = styled.div<{ isSidebar: boolean }>`
  display: flex;

  flex-direction: column;

  padding: 0 20px 7px;

  & > div {
    display: flex;
    margin: 3px 0 2px;
  }

  & > div,
  & > span:last-child {
    align-self: center;
  }

  flex: 1;

  ${({ theme }) => theme.mediaQueries?.md} {
    padding: 0;

    & > div,
    & > span:last-child {
      align-self: ${({ isSidebar }) => (isSidebar ? 'center' : 'flex-start')};
    }
  }
`

const RemainingTokensSection = styled.div<{ isSidebar: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 15px 20px 0;
  flex: 2;

  ${({ theme }) => theme.mediaQueries?.md} {
    padding: ${({ isSidebar }) => (isSidebar ? '0' : '0 0 0 25px')};
  }
`

const PurchasedAmountWrapper = styled.div`
  margin: 20px 30px 0;
  padding: 20px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colorsV2?.light}`};
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Progessbar = styled.div<{ $canPurchase: boolean }>`
  border-radius: 5px;
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.dark2 : theme.colorsV2?.light)};
  border: ${({ theme }) => (theme.isDark ? theme.colorsV2?.light : theme.colorsV2?.textDisabled)};
  height: 7px;
  & > div {
    border-radius: 20px;
    background: ${({ theme, $canPurchase }) =>
      $canPurchase
        ? theme.colorsV2?.textThirdly
        : theme.isDark
        ? theme.colorsV2.textDisabled
        : theme.colorsV2.feedbacks.disableBg};
    height: 7px;
  }
`

interface PurchaseCardProps {
  sale: PurchasableIDO
  projectImage: string
  userAllocationInWei?: string
}

const PurchaseCard: React.FC<PurchaseCardProps> = ({ sale, projectImage, userAllocationInWei }) => {
  const { purchasePeriod, chainId, saleChainId = chainId, paymentToken, saleAddress, stepRequirement } = sale

  const maxPaymentAllocationInWei = useGetMaxPaymentInWeiV2(saleAddress, userAllocationInWei)
  const paymentTokenNeeded = convertFromWei(maxPaymentAllocationInWei, paymentToken.decimals)
  const canPurchase = paymentTokenNeeded.isGreaterThan(0.001)
  const { isJustSale, brand } = useContext(CompanyContext)
  const { t } = useTranslation()
  const countdownInfo = useMemo(() => {
    const endTimestamp = new Date(purchasePeriod.endTime).getTime()
    const currentTimestamp = new Date().getTime()
    const seconds = Math.trunc((endTimestamp - currentTimestamp) / 1000)
    return {
      description: t(`Purchase ${isJustSale ? `ends` : `closes`} in`),
      seconds,
    }
  }, [t, purchasePeriod])

  const linkBtnInfo = useMemo(() => {
    if (paymentToken.symbol === 'USDC' || paymentToken.symbol === 'USDC.e') {
      return null
    }
    if (canPurchase) {
      return {
        text: t(isJustSale ? 'Get more %token% or Wrap your ETH' : 'Get more %token%', { token: paymentToken.symbol }),
        icon: <OpenNewIcon />,
        href: getMoreTokenAddress(paymentToken, { chainId: saleChainId }, brand),
      }
    }
    return null
  }, [t, paymentToken, saleChainId, canPurchase])

  const bodyComp = useMemo(() => {
    return (
      <PublicIDOBody
        sale={sale as PaidStakeSaleConfig}
        projectImage={projectImage}
        userAllocationInWei={userAllocationInWei}
      />
    )
  }, [sale, projectImage, userAllocationInWei])

  return (
    <SaleDetailCard
      requireKYC={!!stepRequirement?.includes(StepRequirementEnum.KYC)}
      targetNetworkID={saleChainId}
      countdownInfo={isJustSale ? undefined : countdownInfo}
      linkBtnInfo={linkBtnInfo}
    >
      {bodyComp}
    </SaleDetailCard>
  )
}

interface BodyProps {
  sale: PurchasableIDO
  totalAllocation: BigNumber
  purchasedAllocation: BigNumber
  totalPurchasedAllocation: BigNumber
  maxTotalPurchasable: number
  totalPaymentTokenNeed: BigNumber
  paymentTokenNeeded: BigNumber
  paymentToken: Token
  saleToken: IDOSaleToken
  projectImage: string
  chainID: number
  salePrice: number
  saleAddress: string
  saleID: string
  whitelistedUrl?: string
  userAllocationInWei?: string
  saleTokenDefaultName?: string
  isPromoCodeEnabled?: boolean
  percentageCashback?: number
  isNftSale?: boolean
  limitPerPurchase?: string
  isPrivate?: boolean
}

const Body: React.FC<BodyProps> = ({
  purchasedAllocation,
  totalAllocation,
  totalPurchasedAllocation,
  maxTotalPurchasable,
  totalPaymentTokenNeed,
  paymentTokenNeeded,
  paymentToken,
  saleToken,
  projectImage,
  chainID,
  salePrice,
  saleAddress,
  whitelistedUrl,
  saleID,
  userAllocationInWei,
  saleTokenDefaultName,
  isPromoCodeEnabled,
  percentageCashback,
  limitPerPurchase,
  isNftSale,
  isPrivate,
  sale,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const hasTotalMaxPurchase = maxTotalPurchasable > 0
  const userData = useSaleUserData(saleID)

  const [inputVal, setInputVal] = useState('')
  const [quantityVal, setQuantityVal] = useState('')
  const balance = convertFromWei(useTokenBalance(paymentToken.address), paymentToken.decimals)
  const { brand } = useContext(CompanyContext)

  const limitPerPurchaseBigInt = new BigNumber(limitPerPurchase)

  const maxAmountToPurchase = BigNumber.min(
    BigNumber.min(
      BigNumber.max(new BigNumber(0), paymentTokenNeeded),
      hasTotalMaxPurchase
        ? limitPerPurchaseBigInt.gte(0)
          ? BigNumber.min(totalPaymentTokenNeed, limitPerPurchaseBigInt)
          : totalPaymentTokenNeed
        : balance,
    ),
    balance,
  )
  const { isJustSale } = useContext(CompanyContext)
  const percentageInt = purchasedAllocation.div(totalAllocation).multipliedBy(100).isGreaterThan(100)
    ? new BigNumber(100)
    : purchasedAllocation.div(totalAllocation).multipliedBy(100)

  const totalPercentageInt = totalPurchasedAllocation
    .div(new BigNumber(maxTotalPurchasable))
    .multipliedBy(100)
    .isGreaterThan(100)
    ? new BigNumber(100)
    : totalPurchasedAllocation.div(new BigNumber(maxTotalPurchasable)).multipliedBy(100)

  const percentage = `${totalAllocation.isEqualTo(0) ? '0' : percentageInt.decimalPlaces(2).toString()}%`
  const totalPercentage = `${totalAllocation.isEqualTo(0) ? '0' : totalPercentageInt.decimalPlaces(2).toString()}%`

  const maxTokenToPurchase = maxAmountToPurchase.dividedBy(salePrice).decimalPlaces(0, BigNumber.ROUND_DOWN)

  const tokenAllowance = userData?.paymentTokenAllowanceInWei

  const approval =
    tokenAllowance === '0' ||
    !tokenAllowance ||
    new BigNumber(convertFromWei(tokenAllowance, paymentToken.decimals)).isLessThan(new BigNumber(inputVal))
      ? ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  const { onApprove, isLoading } = useIdoApprove(saleAddress, paymentToken.address)

  const { onPurchase, isLoading: purchasing } = usePurchaseV2({
    saleAddress,
    whitelistedUrl,
    saleId: saleID,
    userAllocation: userAllocationInWei,
    isPrivate: isPrivate,
  })

  const [onPresentPurchaseModal, onDismiss] = useModalV2({
    modal: (
      <ModalV2
        bodyTitle={t('Confirm Purchase')}
        bodySubtitle={t('Purchase %paymentAmount% %paymentToken% for %share% %saleToken%', {
          paymentAmount: new BigNumber(inputVal).decimalPlaces(2).toNumber().toLocaleString(),
          paymentToken: paymentToken.symbol,
          share: new BigNumber(inputVal).div(salePrice).decimalPlaces(2).toNumber().toLocaleString(),
          saleToken: saleToken?.symbol || '',
        })}
        bodyDescription={t('Confirm this transaction in your wallet')}
        showLoader
        bodyImageWidth={36}
        bodyImageMargin="33px 0 66px 0"
      />
    ),
    options: {
      backdropBlurVal: 'blur(20px)',
      closeOnOverlayClick: false,
    },
  })

  const { toastSuccessV2 } = useToast()

  const onPurchaseSuccess = useCallback(
    (txHash) => {
      toastSuccessV2(
        `Success`,
        `Purchased ${(parseFloat(inputVal) / salePrice).toLocaleString()} ${saleToken.symbol || saleTokenDefaultName}`,
        {
          text: 'View on explorer',
          url: getBscScanLink(txHash, 'transaction', chainID),
        },
      )
      setInputVal('')
      setQuantityVal('')
    },
    [inputVal, salePrice, saleToken, toastSuccessV2, chainID],
  )

  const c = getWithExpiry('PROMO_CODE', 1) || ''

  const lowerCaseC = c.toLowerCase()

  const promoCodeList = PROMO_CODES?.[brand] || AETHIR_CODES

  const [promoCode, setPromoCode] = useState(c?.length > 0 && promoCodeList[lowerCaseC] ? lowerCaseC : '')

  const purchaseHandler = useCallback(async () => {
    onPresentPurchaseModal()
    await onPurchase(getBalanceInWei(new BigNumber(inputVal), paymentToken.decimals, 0), promoCode, onPurchaseSuccess)
    onDismiss()
  }, [inputVal, onPresentPurchaseModal, onPurchase, onDismiss, onPurchaseSuccess, promoCode])

  const isAgreed = localStorage.getItem(localStorageKey(saleID)) != null

  const [onPresentDisclaimerModal] = useModalV2({
    modal: <PurchaseCardDisclaimer purchaseHandler={purchaseHandler} saleID={saleID} chainID={chainID} />,
    options: {
      backdropBlurVal: 'blur(20px)',
      closeOnOverlayClick: false,
    },
  })

  const canPurchase = totalAllocation.minus(purchasedAllocation).isGreaterThan(0.0001)

  let invalidButtonText = t('Enter an amount')
  let isButtonValid = true
  const inputValBigNumber = new BigNumber(inputVal)

  if (balance.isLessThan(inputValBigNumber)) {
    isButtonValid = false
    invalidButtonText = t('Insufficient %token% Balance', { token: paymentToken.symbol })
  } else if (inputValBigNumber.isGreaterThan(maxAmountToPurchase)) {
    isButtonValid = false
    invalidButtonText = t(`Exceeded Purchase Limit`)
  }

  const [isOpen, drawerType] = useIsDrawerOpen()
  const isSidebar = isOpen && drawerType === QUICK_ACTION_DRAWER_TYPE

  const [discountActive, setDiscountActive] = useState(promoCodeList[c.toLowerCase()])
  const [openModalPromo, setOpenModalPromo] = useState(false)
  const handleOpenModalPromo = () => setOpenModalPromo(true)
  const textTitlePromo = isNftSale
    ? `The amount of the rebate you will receive is based on your total purchased amount in ETH. Within (no later than) 2 weeks of purchase, you will be airdropped this amount of wETH below.`
    : 'The amount of the cash-back you will receive is based on your total purchased amount in USD. After hodling the tokens through the cash-back period, you will be airdropped this amount of IDIA. IDIA:USD conversion rate is determined 24 hours before the purchase starts.'

  const purchaseSection = useMemo(() => {
    if (hasTotalMaxPurchase) {
      return (
        <>
          <TotalPurchasedSection isSidebar={isOpen && drawerType === QUICK_ACTION_DRAWER_TYPE}>
            <IFTypography
              variant="overline"
              fontFamily="Roboto"
              ifcolor={canPurchase ? 'textSecondary' : 'textDisabled'}
              lineHeight="18px"
            >
              {t('Total Purchased')}
            </IFTypography>
            <Box>
              <Box height="24px" marginRight="10px">
                <img src={projectImage} alt={saleToken.symbol} width="24px" height="24px" />
              </Box>
              <IFTypography
                variant="body1"
                lineHeight="24px"
                ifcolor={canPurchase ? 'text' : 'textDisabled'}
                fontWeight="bold"
              >
                {purchasedAllocation.isGreaterThan(0)
                  ? `${purchasedAllocation.decimalPlaces(2).toNumber().toLocaleString()} ${
                      saleToken.symbol || saleTokenDefaultName
                    }`
                  : '-'}
              </IFTypography>
            </Box>
            {limitPerPurchaseBigInt.isGreaterThan(0) && (
              <IFTypography
                variant="overline"
                fontFamily="Roboto"
                ifcolor={canPurchase ? 'textSecondary' : 'textDisabled'}
                lineHeight="18px"
              >
                {t('Your Allocation: ')}{' '}
                {`${totalAllocation.decimalPlaces(2).toNumber().toLocaleString()} ${
                  saleToken.symbol || saleTokenDefaultName
                }`}
              </IFTypography>
            )}
          </TotalPurchasedSection>
          <Divider isSidebar={isSidebar} />
          <RemainingTokensSection isSidebar={isSidebar}>
            <IFTypography
              variant="overline"
              fontFamily="Roboto"
              ifcolor={canPurchase ? 'textSecondary' : 'textDisabled'}
              lineHeight="18px"
            >
              {t(`Remaining ${isNftSale ? 'Node' : 'Tokens'} `)}
            </IFTypography>
            <Box marginTop="0">
              <ProgressBar sale={sale} />
            </Box>
          </RemainingTokensSection>
        </>
      )
    }

    return (
      <>
        <TotalAllocationSection isSidebar={isOpen && drawerType === QUICK_ACTION_DRAWER_TYPE}>
          <IFTypography
            variant="overline"
            fontFamily="Roboto"
            ifcolor={canPurchase ? 'textSecondary' : 'textDisabled'}
            lineHeight="18px"
          >
            {t('Total allocation')}
          </IFTypography>
          <Box>
            <Box height="24px" marginRight="10px">
              <img src={projectImage} alt={saleToken.symbol} width="24px" height="24px" />
            </Box>
            <IFTypography
              variant="body1"
              lineHeight="24px"
              ifcolor={canPurchase ? 'text' : 'textDisabled'}
              fontWeight="bold"
            >
              {`${totalAllocation.decimalPlaces(2).toNumber().toLocaleString()} ${
                saleToken.symbol || saleTokenDefaultName
              }`}
            </IFTypography>
          </Box>
          <IFTypography
            variant="overline"
            fontFamily="Roboto"
            ifcolor={canPurchase ? 'textSecondary' : 'textDisabled'}
            lineHeight="18px"
          >
            {`${t('You will need')} ${paymentTokenNeeded.decimalPlaces(10).toLocaleString()} ${paymentToken.symbol}`}
          </IFTypography>
        </TotalAllocationSection>
        <Divider isSidebar={isSidebar} />
        <PurchasedAllocationSection isSidebar={isSidebar}>
          <IFTypography
            variant="overline"
            fontFamily="Roboto"
            ifcolor={canPurchase ? 'textSecondary' : 'textDisabled'}
            lineHeight="18px"
          >
            {t('Purchased')}
          </IFTypography>
          <IFTypography
            variant="overline"
            fontFamily="Roboto"
            ifcolor={canPurchase ? 'textSecondary' : 'textDisabled'}
            lineHeight="18px"
          >
            {purchasedAllocation.decimalPlaces(2).toNumber().toLocaleString()}
          </IFTypography>
          <Progessbar $canPurchase={canPurchase}>
            <Box width={percentage} />
          </Progessbar>
          {/* <ProgressBar sale={sale} /> */}
          <Box textAlign="right" marginTop="4px">
            <IFTypography
              variant="overline"
              fontFamily="Roboto"
              ifcolor={canPurchase ? 'textSecondary' : 'textDisabled'}
              lineHeight="18px"
            >
              {' '}
              {`${paymentTokenNeeded.dividedBy(salePrice).decimalPlaces(2).toNumber().toLocaleString()} ${
                saleToken.symbol || saleTokenDefaultName
              } ${t('left')}`}
            </IFTypography>
          </Box>
        </PurchasedAllocationSection>
      </>
    )
  }, [
    hasTotalMaxPurchase,
    isNftSale,
    purchasedAllocation,
    paymentTokenNeeded,
    salePrice,
    totalPurchasedAllocation,
    maxTotalPurchasable,
  ])

  const ethereumAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/
  const isPromoCodeAWallet = ethereumAddressRegex.test(promoCode)

  return (
    <BodyContainer>
      <BodyHeaderContainer canPurchase={canPurchase} isSidebar={isSidebar} isMaxPurchase={hasTotalMaxPurchase}>
        {purchaseSection}
      </BodyHeaderContainer>
      {canPurchase ? (
        <>
          <InputArea
            balance={balance}
            inputVal={isNftSale ? quantityVal : inputVal}
            iconSrc={isNftSale ? saleToken.image : `/images/tokens/${paymentToken.symbol}.png`}
            symbol={paymentToken.symbol}
            onInputChange={(val) => {
              if (isNftSale) {
                setQuantityVal(val)
                setInputVal((parseInt(val) * salePrice).toFixed(7).toString())
              } else {
                setInputVal(val)
              }
            }}
            maxAmountOnSelect={isNftSale ? maxTokenToPurchase : maxAmountToPurchase}
            isNFTSale={isNftSale}
            salePrice={salePrice}
            paymentToken={paymentToken}
          />
          {!isNftSale && (
            <Box margin="5px 10px 5px 0" textAlign="right">
              <Box display="flex" alignItems="center" columnGap={1} justifyContent="flex-end">
                <IFTypography variant="overline" fontFamily="Roboto" ifcolor="textSecondary" lineHeight="18px">
                  {`1 ${saleToken.symbol || saleTokenDefaultName} = ${salePrice} ${paymentToken.symbol}`}
                </IFTypography>
                <img style={{ width: '16px', height: '16px' }} src={paymentToken.tokenImage} />
              </Box>
            </Box>
          )}

          {isNftSale && (
            <>
              <Box margin="5px 25px 10px 25px" display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center" columnGap={1}>
                  <IFTypography variant="body2" fontFamily="Roboto" ifcolor="textSecondary" lineHeight="18px">
                    {t('Your Balance')}
                  </IFTypography>
                  <img style={{ width: '16px', height: '16px' }} src={paymentToken.tokenImage} />
                </Box>
                <IFTypography
                  variant="body2"
                  fontWeight="bold"
                  fontFamily="Roboto"
                  ifcolor="textSecondary"
                  lineHeight="18px"
                >
                  {`${(balance ?? new BigNumber(0)).decimalPlaces(2).toNumber().toLocaleString()} ${
                    paymentToken.symbol
                  }`}
                </IFTypography>
              </Box>
              <Box margin="5px 25px 10px 25px" display="flex" justifyContent="space-between">
                <IFTypography variant="body2" fontFamily="Roboto" ifcolor="text" lineHeight="18px">
                  {t('Total Price')}
                </IFTypography>
                <IFTypography
                  variant="body2"
                  fontWeight="bold"
                  fontFamily="Roboto"
                  color={theme.isColorOverride ? theme.colorsV2.feedbacks.infoText : theme.common.totalPriceText}
                  lineHeight="18px"
                >
                  {`${(parseFloat(inputVal) || 0) > 0 ? '' : ''} ${(parseFloat(inputVal) || 0).toLocaleString(
                    undefined,
                    { minimumFractionDigits: 2, maximumFractionDigits: 4 },
                  )} ${paymentToken.symbol}`}
                </IFTypography>
              </Box>
            </>
          )}
          {parseFloat(inputVal) > 0 && !isNftSale && (
            <Box margin="5px 10px 10px 10px" display="flex" justifyContent="space-between">
              <IFTypography variant="body2" fontFamily="Roboto" ifcolor="text" lineHeight="18px">
                {t('Receive')}
              </IFTypography>
              <IFTypography variant="body2" fontWeight="bold" fontFamily="Roboto" ifcolor="text" lineHeight="18px">
                {`${(parseFloat(inputVal) / salePrice).toLocaleString()} ${saleToken.symbol || saleTokenDefaultName}`}
              </IFTypography>
            </Box>
          )}
          {canPurchase &&
            isPromoCodeEnabled &&
            (discountActive ? (
              <Box
                display="flex"
                flexDirection="column"
                gap="10px"
                borderRadius="10px"
                marginTop="24px"
                marginBottom="20px"
                sx={{ background: theme.isDark ? 'rgba(21, 21, 46, 0.60)' : '#F2F2F2', padding: ' 10px 15px' }}
              >
                {percentageCashback > 0 ? (
                  <>
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <IFTypography
                          variant="body1"
                          ml="10px"
                          sx={{ fontSize: '14px', color: theme.isDark && 'rgba(250, 250, 250, 0.70)' }}
                        >
                          Applied Code
                        </IFTypography>
                        <IFTypography
                          variant="body1"
                          ml="10px"
                          sx={{ fontSize: '14px', color: theme.isDark && 'rgba(250, 250, 250, 0.70)' }}
                        >
                          ({isPromoCodeAWallet ? `${promoCode.slice(0, 6)}...${promoCode.slice(-4)}` : promoCode})
                        </IFTypography>
                      </Box>

                      <Box display="flex">
                        <IFTypography variant="body1" sx={{ fontSize: '14px' }}>
                          {isPromoCodeAWallet ? percentageCashback : '10'}% Cashback
                        </IFTypography>
                        <Button
                          onClick={() => {
                            setDiscountActive(false)
                            setPromoCode('')
                            localStorage.removeItem('PROMO_CODE')
                          }}
                          sx={{ padding: '0', alignItems: 'flex-start' }}
                        >
                          <IFTypography
                            display="flex"
                            ifcolor="textThirdly"
                            alignItems="flex-start"
                            sx={{
                              fontSize: '12px',
                              fontFamily: 'roboto',
                              textDecorationLine: 'underline',
                              lineHeight: '18px',
                            }}
                          >
                            Remove
                          </IFTypography>
                        </Button>
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" ml="10px">
                      <Box display="flex" gap="5px">
                        <Box>
                          <IFTypography fontSize="14px" sx={{ color: theme.isDark && 'rgba(250, 250, 250, 0.70)' }}>
                            You will receive
                          </IFTypography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <IFTooltip title={textTitlePromo} placement="top">
                            <HelpIcon sx={{ fontSize: '16px', fill: '#5A5A5A' }} />
                          </IFTooltip>
                        </Box>
                      </Box>
                      <IFTypography fontSize="14px" mr={2}>
                        {inputValBigNumber.isGreaterThan(0)
                          ? `${inputValBigNumber
                              .times(isPromoCodeAWallet ? 5 : 10)
                              .div(100)
                              .toFixed(4)
                              .toString()} ${paymentToken.symbol}`
                          : '-'}{' '}
                      </IFTypography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box display="flex" justifyContent="space-between">
                      <IFTypography
                        variant="body1"
                        ml="10px"
                        sx={{ fontSize: '14px', color: theme.isDark && 'rgba(250, 250, 250, 0.70)' }}
                      >
                        Applied Code
                      </IFTypography>
                      <Box display="flex">
                        <IFTypography variant="body1" sx={{ fontSize: '14px' }}>
                          {promoCode}
                        </IFTypography>
                        <Button
                          onClick={() => {
                            setDiscountActive(false)
                            setPromoCode('')
                          }}
                          sx={{ padding: '0' }}
                        >
                          <IFTypography
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            ifcolor="textThirdly"
                            sx={{
                              fontSize: '12px',
                              fontFamily: 'roboto',
                              textDecorationLine: 'underline',
                              lineHeight: '18px',
                            }}
                          >
                            Remove
                          </IFTypography>
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            ) : (
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={handleOpenModalPromo} sx={{ margin: '0 25px 20px 0', padding: '0' }}>
                  <IFTypography
                    sx={{
                      fontSize: '12px',
                      color: theme.common.promoCodeText,
                      fontWeight: 400,
                      fontFamily: 'roboto',
                      display: 'inline-flex',
                      height: '26px',
                      padding: '5px 10px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '5px',
                      flexShrink: '0',
                      borderRadius: '21px',
                      background: isNftSale
                        ? theme.colorsV2.main4
                        : theme.isDark
                        ? 'rgba(21, 21, 46, 0.60)'
                        : '#DEEFFF',
                    }}
                  >
                    Use promo code
                    <PromoBtnIcon fillColor={theme.common.promoCodeText} />
                  </IFTypography>
                </Button>
                <ModalPromo
                  open={openModalPromo}
                  setOpenModalPromo={setOpenModalPromo}
                  setDiscountActive={setDiscountActive}
                  setPromoCode={setPromoCode}
                />
              </Box>
            ))}
          <Box marginTop="30px">
            <ActionBtnWithApproveFlow
              approvalState={approval}
              approveHandler={onApprove}
              decimals={paymentToken.decimals}
              balance={balance}
              userInput={inputVal}
              actionHandler={isAgreed ? purchaseHandler : onPresentDisclaimerModal}
              inProgress={purchasing || isLoading}
              textInvalid={invalidButtonText}
              textWithApproval={t('Purchase')}
              textWithoutApproval={t('Purchase')}
              isValid={isButtonValid}
              isMobileOverride={isSidebar}
            />
          </Box>
        </>
      ) : (
        <Box>
          <Box textAlign="center" sx={{ padding: '40px' }}>
            <IFTypography ifcolor="textSecondary" variant="body2" fontWeight="regular">
              {t('You have purchased all your allocation, please wait until the next step opens')}
            </IFTypography>
          </Box>
          <PurchasedAmountWrapper>
            <IFTypography ifcolor="textSecondary" variant="button" fontWeight="regular">
              {t('Your purchased tokens')}
            </IFTypography>
            <Box display="flex" alignContent="center">
              <Box marginRight="10px">
                <img src={saleToken.image} alt="token_icon" width="36px" />
              </Box>
              <IFTypography ifcolor="text" variant="h2" fontWeight="regular">
                {purchasedAllocation.decimalPlaces(2).toNumber().toLocaleString()}
              </IFTypography>
            </Box>
          </PurchasedAmountWrapper>
        </Box>
      )}
    </BodyContainer>
  )
}

const PublicIDOBody: React.FC<{ sale: PaidStakeSaleConfig; projectImage: string; userAllocationInWei: string }> = ({
  sale,
  projectImage,
  userAllocationInWei,
}) => {
  const userData = useSaleUserData(sale.id)
  const {
    purchasePeriod: { salePrice, maxTotalPurchasable, totalPaymentReceived },
    token: saleToken,
    paymentToken,
    chainId,
    saleChainId = chainId,
    saleAddress,
    id,
    whitelistedUrl,
    saleTokenDefaultName,
    saleAttributes = {},
    limitPerPurchase,
    isPrivate,
  } = sale

  const { isPromoCodeEnabled, isNFTSale, percentageCashback } = saleAttributes

  const maxPaymentAllocationInWei = useGetMaxPaymentInWeiV2(saleAddress, userAllocationInWei)

  const paymentTokenNeeded = convertFromWei(maxPaymentAllocationInWei, paymentToken.decimals)

  const paymentReceivedInWei = (userData && userData.paymentReceivedInWei) ?? new BigNumber(0)

  const paymentReceivedInPaymentToken = convertFromWei(paymentReceivedInWei, paymentToken.decimals)

  const totalPaymentReceivedInPaymentToken = new BigNumber(totalPaymentReceived)

  const purchasedAllocation = paymentReceivedInPaymentToken.dividedBy(salePrice)
  const totalPurchasedAllocation = totalPaymentReceivedInPaymentToken.dividedBy(salePrice)

  const totalAllocationLeft = new BigNumber(maxTotalPurchasable).minus(totalPurchasedAllocation)

  const totalPaymentTokenNeed = totalAllocationLeft.times(salePrice)

  const userAllocation = userAllocationInWei && convertFromWei(new BigNumber(userAllocationInWei), saleToken.decimals)
  const saleTokenAllocation = userAllocation || userData?.userTokenAllocation || new BigNumber(0)

  return (
    <Body
      sale={sale}
      purchasedAllocation={purchasedAllocation}
      maxTotalPurchasable={maxTotalPurchasable}
      limitPerPurchase={limitPerPurchase}
      totalPaymentTokenNeed={totalPaymentTokenNeed}
      totalPurchasedAllocation={totalPurchasedAllocation}
      totalAllocation={saleTokenAllocation}
      paymentTokenNeeded={paymentTokenNeeded}
      userAllocationInWei={userAllocationInWei}
      paymentToken={paymentToken}
      saleToken={saleToken}
      projectImage={projectImage}
      chainID={saleChainId}
      salePrice={salePrice}
      saleAddress={sale.saleAddress}
      saleID={id}
      whitelistedUrl={whitelistedUrl}
      isPromoCodeEnabled={isPromoCodeEnabled}
      percentageCashback={percentageCashback}
      isNftSale={isNFTSale}
      saleTokenDefaultName={saleTokenDefaultName}
      isPrivate={isPrivate}
    />
  )
}

export default PurchaseCard
