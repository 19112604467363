import React, { useContext, useMemo } from 'react'
import {
  AuctionIDOConfig,
  PrivateIDOConfig,
  PaidStakeSaleConfig,
  FreeStakeDropConfig,
  SubscribeableIDO,
  PurchasableIDO,
  LinearVestableIDO,
  IDO,
} from 'state/v2_types'
import { useSaleUserData } from 'state/idos/hooks'
import { isSubscribeableIDO, isPurchaseableIDO, isLinearVestableIDO } from 'state/idos/saleUtil'
import { isSaleParticipated } from 'state/idos/utils'
import { Box } from '@mui/material'
import styled, { useTheme } from 'styled-components'
import { useMatchBreakpoints } from 'uikit'
import ParticipatedBadge from './ParticipatedBadge'
import TopTitle from './TopTitle'
import Header from './Header'
import BottomInfo from './BottomInfo'
import ColorBorderBlankCard from '../ColorBorderBlankCard/ColorBorderBlankCard'
import SaleBriefCardMobile from './SaleBriefCardMobile'
import ActionButton from './ActionButton'
import { SaleFiltered } from 'views/Project/Project'
import ProgressBar from './ProgressBar'
import { CompanyContext } from 'contexts/CompanyContext'

const Container = styled(ColorBorderBlankCard)<{ isBeam?: boolean; isCarv?: boolean }>`
  padding: 40px 30px;
  background: ${({ theme, isCarv }) => isCarv && theme.colorsV2.main2};
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ isBeam, isCarv }) =>
    isBeam ? '0px 0px 25px 0px rgba(0, 36, 164, 0.15);' : isCarv ? '' : '0px 0px 25px 0px rgba(151, 151, 151, 0.15)'};

  ${({ theme }) => theme.mediaQueries?.md} {
    padding: 40px;
  }

  & > div:last-child {
    flex-grow: 1;
  }
`

interface SaleBriefCardProps {
  sale: FreeStakeDropConfig | PrivateIDOConfig | AuctionIDOConfig | PaidStakeSaleConfig | SaleFiltered
}

const SaleBriefCard: React.FC<SaleBriefCardProps> = ({ sale }) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl
  const { colorsV2 } = useTheme()
  const userData = useSaleUserData(sale.id)
  const { isJustSale } = useContext(CompanyContext)
  const isBeam = process.env.REACT_APP_WHITELABEL_BRAND === 'beam'
  const isCarv = process.env.REACT_APP_WHITELABEL_BRAND === 'carv'
  let maxTotalPurchasable = 0
  if (isPurchaseableIDO(sale as any)) {
    const { purchasePeriod } = sale as PurchasableIDO
    maxTotalPurchasable = purchasePeriod?.maxTotalPurchasable
  }
  const isFCFS = maxTotalPurchasable > 0
  const card = useMemo(() => {
    const now = new Date()
    let saleStarted = false
    if (isSubscribeableIDO(sale as SubscribeableIDO)) {
      saleStarted = now >= new Date((sale as SubscribeableIDO).subscribePeriod.startTime)
    } else if (isPurchaseableIDO(sale as PurchasableIDO)) {
      saleStarted = now >= new Date((sale as PurchasableIDO).purchasePeriod.startTime)
    } else if (isLinearVestableIDO(sale as LinearVestableIDO)) {
      saleStarted = now >= new Date((sale as LinearVestableIDO).linearVestInfo.startTime)
    }

    const participated = isSaleParticipated(sale, userData)

    const [borderColor, showBorder] = saleStarted && participated ? [colorsV2.main4, true] : [null, false]
    return isMobile ? (
      <SaleBriefCardMobile borderColor={borderColor} showBorder={showBorder} participated={participated} sale={sale} />
    ) : (
      <Container borderColor={borderColor} isBeam={isBeam} isCarv={isCarv} showBorder={showBorder}>
        {participated && <ParticipatedBadge />}
        <TopTitle sale={sale} />
        <Header sale={sale} isMobile={false} />
        <Box marginTop="30px" />
        <BottomInfo sale={sale} />
        {isFCFS && (
          <Box marginTop="15px">
            <ProgressBar sale={sale} />
          </Box>
        )}
        <ActionButton sale={sale} isMobile={false} />
      </Container>
    )
  }, [sale, userData, isMobile])

  return card
}

export default SaleBriefCard
